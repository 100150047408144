import eventsHandler from '@/services/common/eventsHandler.js'

class qualificationForm extends eventsHandler {
  constructor() {
    super()
    this._qualification = {}
    this._valid = false
  }

  get qualification() {
    const qualification = {
      ...this._qualification
    }
    return qualification
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._qualification.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetHairType() {
    this._qualification = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {qualification: {}})
  }

  updateField(key, value) {
    this._qualification[key] = value
    this._executeCallbacksOf('update', {qualification: this._qualification})
    this._executeCallbacksOf('update-' + key, {key: this._qualification[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(qualification) {
    this._qualification = qualification
    this._executeCallbacksOf('update', {qualification: this._qualification})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new qualificationForm()